<template>
  <centered-layout-empty>
    <template v-if="market">
      <div class="flex items-center justify-between bg-white dark:bg-gray-800 rounded shadow mb-3 h-28 md:h-16 text-sm">
        <div class="hidden md:inline-block h-full rounded-l relative menu mr-2">
          <div class="w-48 bg-blue-500 h-16 rounded-l flex items-center justify-center cursor-pointer"
            @click="toggleWidget()">
            <div class="text-center text-white">
              <p class="uppercase font-bold flex items-center gap-2">
                <img :src="baseMarketImage" alt="coinName" class="w-8 md:w-8" />
                <span v-if="baseMarket">{{ baseMarket.name }}</span>
                {{ translate('markets.markets') }}
              </p>
              <p>
                <Icon name="chevron-down" classes="w-5 h-5 mx-auto" />
              </p>
            </div>
          </div>
        </div>
        <div class="md:flex items-center justify-between flex-1 py-2 px-2 md:px-4">

          <div class="flex items-center w-56">
            <img :src="coinImage" alt="coinName" class="w-8 md:w-12 mr-2" />
            <div class="info-item primary-item">
              <div class="uppercase font-bold">{{ market.Market }}</div>
              <div class="font-bold">{{ market.Name }}</div>
            </div>
          </div>
          <hr class="md:hidden my-2 border-gray-200 dark:border-gray-700" />
          <div class="flex items-center justify-between flex-1">
            <div>
              <div class="uppercase font-bold">{{ translate('markets.last') }}</div>
              <div class="text-red-500 font-bold">{{ market.LastPrice }}</div>
            </div>

            <div>
              <div class="uppercase font-bold">{{ translate('markets.bid') }}</div>
              <div class="text-green-500 font-bold">{{ market.Bid.toFixed(8) }}</div>
            </div>

            <div>
              <div class="uppercase font-bold">{{ translate('markets.ask') }}</div>
              <div class="text-red-500 font-bold">{{ market.Ask.toFixed(8) }}</div>
            </div>

            <div class="hidden lg:block">
              <div class="uppercase font-bold">{{ translate('markets.24h_high') }}</div>
              <div class="font-bold">{{ market.Last24hHigh }}</div>
            </div>

            <div class="hidden lg:block">
              <div class="uppercase font-bold">{{ translate('markets.24h_low') }}</div>
              <div class="font-bold">{{ market.Last24hLow }}</div>
            </div>

            <div class="hidden lg:block">
              <div class="uppercase font-bold">{{ translate('markets.volume') }}</div>
              <div class="font-bold">{{ market.Volume }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row gap-3">
        <div v-show="isMarketsWidget" class="w-full lg:w-72 h-full">
          <div
            class="w-full lg:w-72 bg-gray-50 dark:bg-gray-800 shadow-lg border border-gray-300 dark:border-gray-600 rounded top-16 mt-0.5">
            <div class="p-3 pb-2 flex items-start">
              <button :class="`${activeTab === 'favorites' ? 'active' : ''} btn btn-primary p-1 relative`"
                @click="activeTab = 'favorites'">
                <Icon name="heart" />
              </button>
              <button v-if="isLoggedIn"
                :class="`${activeTab === 'assets' ? 'active' : ''} ml-1 btn btn-primary px-1 py-1 relative`"
                @click="activeTab = 'assets'">
                <Icon name="wallet" />
              </button>
            </div>
            <div class="p-3 pb-0 flex items-center">
              <button v-for="baseMarket in baseMarkets" :key="baseMarket.name"
                :class="`${activeTab === baseMarket.name.toLowerCase() ? 'active' : ''} mr-1 btn btn-primary px-2.5 relative`"
                @click="switchBaseMarket(baseMarket)">{{ baseMarket.name }}
              </button>
            </div>
            <div class="p-3" v-if="activeTab !== 'assets' && activeTab !== 'favorites'">
              <input ref="keywordInput" v-model="keyword" aria-label="" class="form-input w-full h-9"
                placeholder="Search..." type="text" />
              <div class="clearfix"></div>
            </div>
            <div class="flex items-center justify-between mx-3 p-1 mb-1"
              v-if="activeTab !== 'favorites' && activeTab !== 'assets'">
              <p class="uppercase font-bold text-sm">Volume</p>
              <p class="uppercase font-bold text-sm mr-8">Price</p>
            </div>
            <div
              class="h-80 md:h-screen overflow-y-scroll scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
              <template v-if="activeTab === 'assets'">
                <div class="mt-5">
                  <template v-if="myBalance.filter(b => Number(b.balance) > 0).length">
                    <div v-for="record in myBalance" :key="record.id"
                      class="mx-3 p-2 mb-1 rounded bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-900">
                      <div class="flex items-center justify-between text-sm">
                        <div class="flex items-center font-medium">
                          <img class="w-6 mr-2" :src="generateImage(record.name)" alt="" />
                          {{ `${record.name} ${record.shortname}` }}
                        </div>
                        <div class="text-gray-600 dark:text-gray-600">
                          {{ record.balance }}
                        </div>
                      </div>
                      <div class="flex items-center justify-end text-sm gap-2" v-if="record.id !== 1">
                        <a @click="loadMarket(record, 'btc')"><img class="w-6"
                            src="https://s3-eu-west-1.amazonaws.com/cointopay/img/bitcoin_dash2.png" alt="btc" /></a>
                        <a @click="loadMarket(record, 'ltc')" v-if="record.id !== 2"><img class="w-6"
                            src="https://s3-eu-west-1.amazonaws.com/cointopay/img/litecoin_dash2.png" alt="btc" /></a>
                        <a @click="loadMarket(record, 'doge')" v-if="record.id !== 9"><img class="w-6"
                            src="https://s3-eu-west-1.amazonaws.com/cointopay/img/dogecoin_dash2.png" alt="doge" /></a>
                        <a @click="loadMarket(record, 'eurx')" v-if="record.id !== 726"><img class="w-6"
                            src="https://s3-eu-west-1.amazonaws.com/cointopay/img/banckrypto_dash2.png"
                            alt="eurx" /></a>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <p class="p-4 pt-0 text-center">You don't have any assets</p>
                  </template>
                </div>
              </template>

              <template v-if="activeTab === 'favorites'">
                <div class="mt-1">
                  <template v-if="favoriteMarkets.length">
                    <div v-for="favoriteMarket in favoriteMarkets" :key="favoriteMarket.Ticker"
                      :class="`${favoriteMarket.Ticker === market.Ticker ? 'bg-blue-100 dark:bg-gray-800' : ''}`"
                      class="mx-3 p-2 mb-1 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-900">
                      <div class="flex items-center gap-2">
                        <div class="flex-1" @click="selectMarket(favoriteMarket)">
                          <div class="flex items-center justify-between text-sm">
                            <div class="font-medium">{{ marketPair(favoriteMarket) }}</div>
                            <div class="text-red-600" @click="removeFav(favoriteMarket)">
                              <Icon name="heart" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <p class="p-4 pt-0 text-center">You don't have any favorites</p>
                  </template>
                </div>
              </template>

              <template v-if="['btc', 'ltc', 'doge', 'eurx'].indexOf(activeTab) !== -1">
                <template v-if="records.length">
                  <div v-for="record in records" :key="record.id"
                    :class="`${record.Ticker === market.Ticker ? 'bg-blue-100 dark:bg-gray-800' : ''}`"
                    class="mx-3 p-2 mb-1 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-900">
                    <div class="flex items-center gap-2">
                      <div class="flex-1" @click="selectMarket(record)">
                        <div class="flex items-center justify-between text-sm">
                          <div class="font-medium">{{ `${record.Name} ${record.ShortName}` }}</div>
                          <div
                            :class="`${record.PercentageChange < 0 ? 'text-red-600 dark:text-red-600' : 'text-green-600 dark:text-green-600'}`">
                            {{ record.PercentageChange }}%
                          </div>
                        </div>
                        <div class="flex items-center justify-between text-sm">
                          <div class="font-medium">{{ record.Volume }}</div>
                          <div>{{ record.LastPrice }}</div>
                        </div>
                      </div>
                      <div
                        :class="`${isFavMarket(record.Ticker) ? 'text-red-600' : 'text-gray-300 dark:text-gray-500'}`"
                        @click="toggleFav(record)">
                        <Icon name="heart" />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <p class="p-4 pt-0 text-center">
                    No {{ activeTab === 'btc' ? 'markets' : 'favorites' }} found
                  </p>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="w-full" :style="containerW" ref="chartContainer">
          <!-- Charts -->
          <tab-set full="" :action="true">
            <tab :name="translate('markets.charts.market_chart')" slug="market-chart">
              <!-- Market Chart -->
              <market-chart :width="chartW" :redraw="redraw" :refresh="refresh.marketChart"></market-chart>
            </tab>
            <tab :name="translate('markets.charts.depth_chart')" slug="depth-chart">
              <!-- Depth Chart -->
              <depth-chart :width="chartW" :redraw="redraw" :refresh="refresh.depthChart"></depth-chart>
            </tab>
          </tab-set>

          <!-- Buy/Sell Forms -->
          <div class="grid grid-cols-2 gap-2 lg:gap-4">
            <div class="col-span-2 lg:col-span-1">
              <!-- Buy Form -->
              <buy-form :data="buyData" :balance="baseBalance" :sellWallData="sellWallData"></buy-form>
            </div>
            <div class="col-span-2 lg:col-span-1">
              <sell-form :data="sellData" :balance="balance" :buyWallData="buyWallData"></sell-form>
            </div>
          </div>

          <!-- Buy/Sell Walls -->
          <div class="grid grid-cols-2 gap-2 lg:gap-4">
            <div class="col-span-2 lg:col-span-1 mb-4">
              <wall :data="{
      data: sellWallData,
      title: translate('markets.trading.sell'),
      currency: market.ShortName,
      type: 'sell'
    }" @populate="populate"></wall>
            </div>
            <div class="col-span-2 lg:col-span-1 mb-4">
              <wall :data="{
      data: buyWallData,
      title: translate('markets.trading.buy'),
      currency: baseMarket.name,
      type: 'buy'
    }" @populate="populate"></wall>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-2 lg:gap-4">
            <div class="col-span-3 lg:col-span-2">
              <history :refresh="refresh" :wallId="wallId" @populateForm="populateForm"></history>
            </div>
            <div class="col-span-3 lg:col-span-1">
              <comments></comments>
            </div>
          </div>
        </div>
      </div>
    </template>
  </centered-layout-empty>
  <!-- Login Modal -->
  <login-modal></login-modal>
</template>

<script setup>
import CenteredLayoutEmpty from '@/components/layouts/CenteredLayoutEmpty.vue';
import { useStore } from 'vuex';
import { computed, inject, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import Icon from '@/components/Icon.vue';
import includes from 'lodash/includes';
import find from 'lodash/find';
import startsWith from 'lodash/startsWith';
import { FETCH_MARKETS, SELECT_BASE_MARKET, SET_FAVORITE_MARKETS, SET_MARKET, FETCH_ACCOUNT_INFO, PERSIST_AUTH, SET_LOGIN_TYPE } from '@/store/keys';
import { useRoute, useRouter } from 'vue-router';
import LoginModal from '@/components/auth/modals/LoginModal.vue';
import MarketChart from '@/components/trade-view/MarketChart.vue';
import DepthChart from '@/components/trade-view/DepthChart.vue';
import TabSet from '@/components/common/TabSet.vue';
import Tab from '@/components/common/Tab.vue';
import Comments from '@/components/trade-view/Comments.vue';
import History from '@/components/trade-view/History.vue';
import BuyForm from '@/components/trade-view/BuyForm.vue';
import Wall from '@/components/trade-view/Wall.vue';
import SellForm from '@/components/trade-view/SellForm.vue';
import remove from 'lodash/remove';
import compositionUtils from '@/compositionUtils';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import { useCookies } from 'vue3-cookies';

const url = process.env.VUE_APP_API_URL;

const { numToPlain, baseMarketName } = compositionUtils();
const { cookies } = useCookies();

const store = useStore();
const router = useRouter();
const route = useRoute();

const translate = inject('translate');
const http = inject('http');

const chartContainer = ref(null);
const myBalance = ref([]);
const isMarketsWidget = ref(true);
const isLoading = ref(false);
const keyword = ref('');
const keywordInput = ref(null);
let socket = null;
let tradingSocket = null;
let traderSocket = null;
let transactionSocket = null;
const isSocketDestroyed = ref(false);
const activeTab = ref('btc');
const favoriteMarkets = ref(store.state.favMarkets || []);
const refresh = ref({
  depthChart: null,
  marketChart: null,
  myOrders: null,
  tradingHistory: null,
  markets: null,
  walls: null,
  balance: null
});
const redraw = ref(null);
const chartW = ref(null);
const wallId = ref(null);
const isWallLoading = ref(false);
const buyWallData = ref([]);
const sellWallData = ref([]);
const buyData = ref(null);
const sellData = ref(null);
const baseBalance = ref(0);
const balance = ref(0);
const ticker = ref('BTC_LTC');
const windowW = ref(null);

const isLoggedIn = computed(() => store.state.isLoggedIn);
const markets = computed(() => store.state.markets);
const market = computed(() => store.state.market);
const user = computed(() => store.state.user);
const baseMarkets = computed(() => {
  const bMList = store.state.baseMarkets;
  const list = [
    { id: 1, name: 'BTC', fullName: 'bitcoin', isSelected: true },
    { id: 2, name: 'LTC', fullName: 'litecoin', isSelected: false },
    { id: 9, name: 'Doge', fullName: 'dogecoin', isSelected: false },
    { id: 726, name: 'EURx', fullName: 'banckrypto', isSelected: false }
  ];
  if (bMList.length < list.length) {
    for (let i = 0; i < list.length; i++) {
      const b = find(bMList, { name: list[i].name });
      if (b == null) {
        bMList.push(list[i]);
      }
    }
  }
  return bMList;
});
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);
const coinImage = computed(() => {
  return market.value
    ? (
      'https://s3-eu-west-1.amazonaws.com/cointopay/img/' +
      market.value.Name.toLowerCase() +
      '_dash2.png'
    )
    : null;
});
const baseMarketImage = computed(() => {
  const name = baseMarket.value ? nameToFullName(baseMarket.value.name) : null;
  return name
    ? 'https://s3-eu-west-1.amazonaws.com/cointopay/img/' + name + '_dash2.png'
    : null;
});
const filteredMarkets = computed(() => {
  let filteredMarkets = [...store.state?.markets];
  if (activeTab.value === 'favorites') {
    if (favoriteMarkets.value.length > 0) {
      filteredMarkets = filteredMarkets.filter(m => {
        return favoriteMarkets.value.indexOf(m.Ticker) !== -1;
      });
    } else {
      return [];
    }
  }
  return filteredMarkets;
});
const records = computed(() => {
  let markets = filteredMarkets.value;
  if (keyword.value !== '' && markets && markets.length > 0) {
    const q = keyword.value.toLowerCase();
    markets = markets.filter(m => {
      return (
        includes(m.Name.toLowerCase(), q) ||
        includes(m.Market.toLowerCase(), q)
      );
    });
  }
  return markets;
});
const containerW = computed(() => {
  if (isMarketsWidget.value && windowW.value > 1023) {
    return 'width: calc(100% - 18.76rem)';
  }
  return 'width: 100%';
});

watch(() => refresh.value, (newVal, oldValue) => {
  if (newVal !== null) {
    if (newVal.markets !== oldValue.markets && !isLoading.value) {
      fetchMarkets();
    }
    if (newVal.walls !== oldValue.walls && !isWallLoading.value) {
      fetchWallsData(false);
    }
    if (newVal.balance !== oldValue.balance) {
      fetchBalance();
    }
  }
});
const generateImage = (name) => {
  return (
    'https://s3-eu-west-1.amazonaws.com/cointopay/img/' +
    name.toLowerCase() +
    '_dash2.png'
  );
};

const loadMarket = (balance, mName) => {
  if (balance.id !== 1) {
    const bMarket = find(baseMarkets.value, (b) => b.name.toLowerCase() === mName.toLowerCase());
    if (bMarket) {
      mName = (mName === 'doge') ? 'dog' : (mName === 'eurx' ? 'erx' : mName);
      const ticker = mName.toUpperCase() + '_' + balance.longname.toUpperCase();
      switchBaseMarket(bMarket, ticker);
    }
  }
};

const selectMarket = (record, isFromBaseMarket = false) => {
  // check if base market is correct
  let t = record.Ticker.split('_')[0];
  t = nameToFull(t);
  const m = find(baseMarkets.value, (b) => {
    return b.name.toLowerCase() === t.toLowerCase();
  });
  if (!isFromBaseMarket && m.name !== baseMarket.value.name) {
    // set base market
    switchBaseMarket(m);
  } else {
    ticker.value = record.Ticker;
    store.commit(SET_MARKET, record);
    router.push('/tradeview/' + record.Market);
    const timer = (new Date()).getTime();
    refresh.value = {
      depthChart: timer,
      marketChart: timer,
      myOrders: timer,
      tradingHistory: timer,
      markets: timer,
      walls: timer,
      balance: timer
    };
  }
};

const fetchMarkets = () => {
  isLoading.value = true;
  store.dispatch(FETCH_MARKETS, baseMarket.value.name).then(() => {
    isLoading.value = false;
    const m = find(markets.value, { Ticker: ticker.value });
    if (m && (!market.value || market.value.Ticker !== m.Ticker)) {
      store.commit(SET_MARKET, m);
      const timer = (new Date()).getTime();
      refresh.value = {
        depthChart: timer * Math.random(),
        marketChart: timer * Math.random(),
        myOrders: timer * Math.random(),
        tradingHistory: timer * Math.random(),
        markets: timer * Math.random(),
        walls: timer * Math.random(),
        balance: timer * Math.random()
      };
    }
  });
};

const fetchBalanceList = () => {
  if (!user.value) {
    return;
  }
  const params = {
    Call: 'BalanceOverview',
    output: 'json',
    MerchantID: user.value.ID,
    APIKey: user.value.APIKey
  };
  http.get(`${url}/v2REAPI`, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    let data = response.data;
    if (data.length) {
      data = data.filter(d => d.securecloud !== '2');
    }
    if (data !== 'authentication failed') {
      data.map(balance => {
        return {
          ...balance,
          balance: numToPlain(balance.balance)
        };
      });
      remove(data, { id: 0 });
      data = orderBy(data, ['fiatvalue'], ['desc']);
      myBalance.value = data;
    }
  }).catch(error => {
    console.log(error);
  });
};

const fetchWallsData = (isLoader = true) => {
  if (isLoader) {
    isWallLoading.value = true;
  }
  const params = {
    Call: 'ShowWalls',
    AltCoinID: market.value.AltCoinID,
    Output: 'json',
    MerchantID: 1,
    APIKey: '_',
    BaseMarket: baseMarketName(baseMarket.value.name)
  };
  const endPoint = 'https://artemis.cointopay.com/trading/';

  http.get(endPoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    isWallLoading.value = false;
    const data = response.data.data;
    if (typeof data.bids !== 'undefined') {
      if (buyWallData.value.length > 0 && data.bids.length > 0) {
        buyWallData.value = data.bids.map(record => {
          // compare old data for change
          const old = find(buyWallData.value, { ID: record.ID });
          return {
            ...record,
            isUpdated: old && Number(old.Amount) !== Number(record.Amount)
          };
        });
      } else {
        buyWallData.value = data.bids;
        buyWallData.value.map(record => {
          return {
            ...record,
            isUpdated: false
          };
        });
      }
    }
    if (typeof data.asks !== 'undefined') {
      if (sellWallData.value.length > 0 && data.asks.length > 0) {
        sellWallData.value = data.asks.map(record => {
          // compare old data for change
          const old = find(sellWallData.value, { ID: record.ID });
          return {
            ...record,
            isUpdated: old && Number(old.Amount) !== Number(record.Amount)
          };
        });
      } else {
        sellWallData.value = data.asks;
        sellWallData.value.map(record => {
          return {
            ...record,
            isUpdated: false
          };
        });
      }
    }
  }).catch(error => {
    isWallLoading.value = false;
    console.log(error);
  });
};

const fetchBalance = () => {
  if (user.value) {
    const params = {
      Call: 'Balance',
      MerchantID: user.value.ID,
      APIKey: user.value.APIKey,
      AltCoinID: market.value.AltCoinID,
      output: 'json'
    };
    const btcParams = {
      Call: 'Balance',
      MerchantID: user.value.ID,
      APIKey: user.value.APIKey,
      AltCoinID: baseMarket.value.id,
      output: 'json'
    };
    Promise.all([
      http.get(`${url}/v2REAPI`, {
        headers: { 'Content-Type': 'application/json' },
        params
      }),
      http.get(`${url}/v2REAPI`, {
        headers: { 'Content-Type': 'application/json' },
        params: btcParams
      })
    ]).then(response => {
      balance.value = response[0].data;
      baseBalance.value = response[1].data;
    }).catch(_error => { });
  }
};

const populate = (data) => {
  if (data.type === 'sell') {
    buyData.value = data.record;
  } else {
    sellData.value = data.record;
  }
};

const populateForm = (data) => {
  if (data.TradeFlexTypeID === 1) { // Buy
    buyData.value = {
      Amount: data.Amount,
      Price: data.Price,
      Total: data.Total
    };
  } else { // Sell
    sellData.value = {
      Amount: data.Amount,
      Price: data.Price,
      Total: data.Total
    };
  }
};

const toggleWidget = () => {
  isMarketsWidget.value = !isMarketsWidget.value;
  setTimeout(() => {
    keywordInput.value.focus();
  }, 200);
  setTimeout(() => {
    resizeHandler();
    chartW.value = chartContainer.value.offsetWidth;
    // Redraw graphs
    redraw.value = (new Date()).getTime();
  }, 100);
};

const toggleFav = (record) => {
  const index = findIndex(favoriteMarkets.value, { Ticker: record.Ticker });
  if (index === -1) {
    favoriteMarkets.value.push(record);
  } else {
    favoriteMarkets.value.splice(index, 1);
  }
  store.commit(SET_FAVORITE_MARKETS, favoriteMarkets.value);
};

const removeFav = (record) => {
  const index = findIndex(favoriteMarkets.value, { Ticker: record.Ticker });
  if (index !== -1) {
    favoriteMarkets.value.splice(index, 1);
    store.commit(SET_FAVORITE_MARKETS, favoriteMarkets.value);
  }
};

const marketPair = (record) => {
  let ticker = record.Ticker;
  if (ticker.startsWith('DOG')) {
    ticker = ticker.replace('DOG', 'DOGE');
  } else if (ticker.startsWith('ECX')) {
    ticker = ticker.replace('ECX', 'EURx');
  }
  return ticker.replace('_', '/');
};

const isFavMarket = (Ticker) => {
  if (favoriteMarkets.value) {
    return findIndex(favoriteMarkets.value, { Ticker }) !== -1;
  }
  return false;
};

const resizeHandler = () => {
  windowW.value = document.getElementsByTagName('body')[0].offsetWidth;
};

const switchBaseMarket = (baseMarket, marketTicker = null) => {
  const baseMarketsList = baseMarkets.value.map(b => {
    return {
      ...b,
      isSelected: b.name.toLowerCase() === baseMarket.name.toLowerCase()
    };
  });
  activeTab.value = baseMarket.name.toLowerCase();
  store.commit(SELECT_BASE_MARKET, baseMarketsList);
  store.dispatch(FETCH_MARKETS, baseMarket.name).then(() => {
    if (marketTicker) {
      const m = find(markets.value, { Ticker: marketTicker });
      if (m) {
        selectMarket(m, true);
      } else {
        selectMarket(markets.value[0], true);
      }
    } else {
      selectMarket(markets.value[0], true);
    }
  });
};

const nameToFullName = (name) => {
  const mList = {
    btc: 'bitcoin',
    ltc: 'litecoin',
    doge: 'dogecoin',
    eurx: 'banckrypto'
  };
  return mList[name.toLowerCase()] || 'bitcoin';
};

const nameToFull = (name) => {
  const mList = {
    btc: 'BTC',
    ltc: 'LTC',
    dog: 'DOGE',
    erx: 'EURx'
  };
  return mList[name.toLowerCase()] || 'BTC';
};

const idToName = (id) => {
  const mList = {
    1: 'btc',
    2: 'ltc',
    9: 'dog',
    726: 'erx'
  };
  return mList[id] || 'btc';
};

const generateTicker = (val) => {
  const parts = val.split('_');
  const m = parts[0].toLowerCase() === 'doge' ? 'dog' : 'erx';
  return m.toUpperCase() + '_' + parts[1];
};

const initSocketTrade = () => {
  socket = new WebSocket('wss://wss.cointopay.com/trade');
  socket.onmessage = (evt) => {
    if (evt) {
      const data = evt.data.trim();
      const parts = data.split(':');
      if (parts.length >= 2) {
        setTimeout(() => {
          const timer = (new Date()).getTime();
          wallId.value = null;
          if (startsWith(data, 'CANCEL')) {
            refresh.value = {
              ...refresh.value,
              walls: timer * Math.random(),
              balance: timer * Math.random(),
              markets: timer * Math.random(),
              myOrders: timer * Math.random(),
              depthChart: timer * Math.random()
            };
            fetchBalanceList();
            wallId.value = Number(parts[1]);
          } else {
            const coinID = parts[1].split(',')[0];
            if (coinID.trim().toString() === market.value.AltCoinID.toString()) {
              if (startsWith(data, 'BUY') || startsWith(data, 'SELL')) {
                refresh.value = {
                  ...refresh.value,
                  walls: timer * Math.random(),
                  myOrders: timer * Math.random(),
                  balance: timer * Math.random(),
                  markets: timer * Math.random(),
                  depthChart: timer * Math.random(),
                  tradingHistory: timer * Math.random()
                };
                fetchBalanceList();
              } else if (startsWith(data, 'TRADE')) {
                refresh.value = {
                  ...refresh.value,
                  walls: timer * Math.random(),
                  tradingHistory: timer * Math.random(),
                  myOrders: timer * Math.random(),
                  markets: timer * Math.random(),
                  marketChart: timer * Math.random(),
                  depthChart: timer * Math.random(),
                  balance: timer * Math.random()
                };
                fetchBalanceList();
              } else if (startsWith(data, 'CHARTUPDATE')) {
                refresh.value = {
                  ...refresh.value,
                  marketChart: timer * Math.random()
                };
              }
            }
          }
        }, 3000);
      }
    }
  };

  socket.onclose = (evt) => {
    if (!isSocketDestroyed.value) {
      initSocketTrade();
    }
  };
};

const initSocketTrading = () => {
  tradingSocket = new WebSocket('wss://wss.cointopay.com/trading');
  tradingSocket.onmessage = (evt) => {
    if (evt) {
      const data = evt.data.trim();
      const parts = data.split(':');
      if (parts.length >= 2) {
        setTimeout(() => {
          const timer = (new Date()).getTime();
          wallId.value = null;
          if (startsWith(data, 'CANCEL')) {
            refresh.value = {
              ...refresh.value,
              walls: timer * Math.random(),
              balance: timer * Math.random(),
              markets: timer * Math.random(),
              myOrders: timer * Math.random(),
              depthChart: timer * Math.random()
            };
            fetchBalanceList();
            wallId.value = Number(parts[1]);
          } else {
            const coinID = parts[1].split(',')[0];
            if (coinID.trim().toString() === market.value.AltCoinID.toString()) {
              if (startsWith(data, 'BUY') || startsWith(data, 'SELL')) {
                refresh.value = {
                  ...refresh.value,
                  walls: timer * Math.random(),
                  myOrders: timer * Math.random(),
                  balance: timer * Math.random(),
                  markets: timer * Math.random(),
                  depthChart: timer * Math.random(),
                  tradingHistory: timer * Math.random()
                };
                fetchBalanceList();
              } else if (startsWith(data, 'TRADE')) {
                refresh.value = {
                  ...refresh.value,
                  walls: timer * Math.random(),
                  tradingHistory: timer * Math.random(),
                  myOrders: timer * Math.random(),
                  markets: timer * Math.random(),
                  marketChart: timer * Math.random(),
                  depthChart: timer * Math.random(),
                  balance: timer * Math.random()
                };
                fetchBalanceList();
              } else if (startsWith(data, 'CHARTUPDATE')) {
                refresh.value = {
                  ...refresh.value,
                  marketChart: timer * Math.random()
                };
              }
            }
          }
        }, 3000);
      }
    }
  };

  tradingSocket.onclose = (evt) => {
    if (!isSocketDestroyed.value) {
      initSocketTrading();
    }
  };
};

const initSocketTrader = () => {
  traderSocket = new WebSocket('wss://artemis.cointopay.com/trading/trader');
  traderSocket.onmessage = (evt) => {
    if (evt) {
      let data = evt.data.trim();
      if (data) {
        data = JSON.parse(data);
        if (Number(data.BaseMarketID) === Number(baseMarket.value.id)) {
          wallId.value = null;
          const delays = [3000, 2000, 2000];
          for (let i = 0; i < delays.length; i++) {
            const timer = (new Date()).getTime();
            setTimeout(() => {
              if (data.Action === 'CANCEL') {
                refresh.value = {
                  ...refresh.value,
                  walls: timer * Math.random(),
                  balance: timer * Math.random(),
                  markets: timer * Math.random(),
                  myOrders: timer * Math.random(),
                  depthChart: timer * Math.random()
                };
                fetchBalanceList();
                wallId.value = Number(data.OtherID);
              } else {
                if (data.AltCoinID.toString() === market.value.AltCoinID.toString()) {
                  if (data.Action === 'BUY' || data.Action === 'SELL') {
                    refresh.value = {
                      ...refresh.value,
                      walls: timer * Math.random(),
                      myOrders: timer * Math.random(),
                      balance: timer * Math.random(),
                      markets: timer * Math.random(),
                      depthChart: timer * Math.random(),
                      tradingHistory: timer * Math.random()
                    };
                    fetchBalanceList();
                  } else if (data.Action === 'TRADE') {
                    refresh.value = {
                      ...refresh.value,
                      walls: timer * Math.random(),
                      tradingHistory: timer * Math.random(),
                      myOrders: timer * Math.random(),
                      markets: timer * Math.random(),
                      balance: timer * Math.random(),
                      marketChart: timer * Math.random(),
                      depthChart: timer * Math.random()
                    };
                    fetchBalanceList();
                  } else if (data.Action === 'CHARTUPDATE' &&
                    data.AltCoinID.toString() === market.value.AltCoinID.toString() &&
                    baseMarket.value.toString() === idToName(data.BaseMarketID)
                  ) {
                    refresh.value = {
                      ...refresh.value,
                      marketChart: timer * Math.random()
                    };
                  }
                }
              }
            }, delays.slice(0, i + 1).reduce((a, b) => a + b, 0));
          }
        }
      }
    }
  };

  traderSocket.onclose = (evt) => {
    if (!isSocketDestroyed.value) {
      initSocketTrader();
    }
  };
};

const initSocketTransaction = () => {
  transactionSocket = new WebSocket('wss://wss.cointopay.com/balance');
  transactionSocket.onmessage = (evt) => {
    if (evt) {
      if (evt.data) {
        const parts = evt.data.split(':');
        if (parts.length >= 3 && user.value) {
          if (parts[0].toString() === user.value.ID.toString()) {
            fetchBalanceList();
          }
        }
      }
    }
  };

  transactionSocket.onclose = (evt) => {
    if (!isSocketDestroyed.value) {
      initSocketTransaction();
    }
  };
};
const persistUser = (user) => {
  delete user.Status;
  // Set cookie
  cookies.set('JSESSIONID', user.SessionID, {
    expires: '1Y',
    domain: '.cointopay.com'
  });

  store.dispatch(PERSIST_AUTH, user).then(() => {
    // Get account info
    store.dispatch(FETCH_ACCOUNT_INFO).then(response => {
      store.commit(SET_LOGIN_TYPE, false);
    }).catch(error => {
      console.log(error.response);
    });
  });
};
onBeforeMount(() => {
  if (Object.keys(route.query).length > 0 && route.query?.s !== '') {
    let u = atob(route.query.s);
    if (u) {
      u = JSON.parse(u);
    }
    persistUser(u);
    router.replace({
      name: router.currentRoute.name,
      query: {},
      params: router.currentRoute.params
    });
  }
  ticker.value = route.params.market;
  // Value market value
  if (ticker.value) {
    let m = ticker.value.split('_')[0].toLowerCase();
    if (m) {
      if (['ltc', 'btc', 'doge', 'eurx'].indexOf(m.toLowerCase()) === -1) {
        m = nameToFull(m);
      } else {
        ticker.value = generateTicker(ticker.value);
      }
      if (!baseMarket.value || baseMarket.value.name.toLowerCase() !== m.toLowerCase()) {
        const baseMarket = find(baseMarkets.value, (b) => {
          return b.name.toLowerCase() === m.toLowerCase();
        });
        switchBaseMarket(baseMarket, ticker.value);
      }
    }
  }
  if (markets.value && markets.value.length) {
    const m = find(markets.value, { Ticker: ticker.value });
    if (m) {
      store.commit(SET_MARKET, m);
    }
    if (!market.value) {
      return router.push('/markets');
    } else {
      fetchMarkets();
      initSocketTrade();
      initSocketTrader();
      initSocketTrading();
      initSocketTransaction();
      fetchWallsData();
      fetchBalance();
    }
  } else {
    store.dispatch(FETCH_MARKETS).then(() => {
      const m = find(markets.value, { Ticker: ticker.value });
      if (m) {
        store.commit(SET_MARKET, m);
      }
      if (!market.value) {
        return router.push('/markets');
      } else {
        fetchMarkets();
        initSocketTrade();
        initSocketTrader();
        initSocketTrading();
        initSocketTransaction();
        fetchWallsData();
        fetchBalance();
      }
    });
  }
  if (isLoggedIn.value) {
    fetchBalanceList();
  }
});

onMounted(() => {
  window.addEventListener('resize', resizeHandler);
  if (baseMarket.value) {
    activeTab.value = baseMarket.value.name.toLowerCase();
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler);
  isSocketDestroyed.value = true;
  if (socket != null) {
    socket.close();
  }
  if (tradingSocket != null) {
    tradingSocket.close();
  }
  if (traderSocket != null) {
    traderSocket.close();
  }
  if (transactionSocket != null) {
    transactionSocket.close();
  }
});

</script>

<style scoped lang="scss">
.menu {
  &::before {
    content: '';
    position: absolute;
    border: 8px solid transparent;
    right: -15px;
    top: calc(100% / 2 - 6px);
    border-left-color: #446bb3 !important;
  }
}

.active {
  &::after {
    content: '';
    border: 6px solid transparent;
    position: absolute;
    bottom: -13px;
    left: calc(100% / 2 - 6px);
    border-top-color: #446bb3 !important;
  }

  &:hover {
    &::after {
      border-top-color: rgb(59, 75, 121) !important;
    }
  }
}
</style>
